<template>
  <div class="companyInfo main">
    <div class='flex-column'>
      <div class='flex-start' v-if='companyInfo.status || companyInfo.status == -1 || companyInfo.status == 0'>
        <div class='home-title'>{{ companyInfo.companyName || '' }}</div>
        <el-tag style='position:relative;top:12px;margin-right:12px;'
          :type="{ 0:'warning', 1:'', 2:'danger' }[companyInfo.status]">{{ { 0:'未认证', 1:'认证通过', 2:'认证失败' }[companyInfo.status]}}</el-tag>
        <div v-if='[-1,0].includes(companyInfo.status)' class='home-title-tips'>当前企业尚未完成认证，无法发布应用 <el-link
            v-if='companyInfo.status == -1' @click='goAuthentication(1)'>前往认证</el-link></div>
        <div v-if='companyInfo.status == 2' class='home-title-tips'><el-link @click='goAuthentication(1)'>重新认证</el-link>
        </div>
      </div>
      <div class='flex' v-if='companyInfo.status == 1'>
        <div class='flex-column border-w' style='width:calc(100% - 40px);height:100%;'>
          <div class='flex' style='height:calc(40% - 12px);padding:20px;'>
            <div class='home-card flex-column' v-for='item in countList' :key='item.index'>
              <div>{{ item.appType }}</div>
              <div class='home-card-text'>{{ item.total }}</div>
            </div>
            <!-- <div class='home-card' style='background: linear-gradient(45deg, #7dd353, #e1f3d8);'>
                            <div>场景应用</div>
                            <div>{{ countObj.allCount }}</div>
                        </div>
                        <div class='home-card' style='background: linear-gradient(45deg, #f3b75c, #faecd8);'>
                            <div>AI算法</div>
                            <div>{{ countObj.allCount }}</div>
                        </div>
                        <div class='home-card' style='background: linear-gradient(45deg, #f17474, #fde2e2);'>
                            <div>开发平台</div>
                            <div>{{ countObj.allCount }}</div>
                        </div> -->
          </div>
        </div>
      </div>
      <div class='flex' v-if='companyInfo.status == 1'>
        <div class='flex-column home-bottom-left'>
          <div class='flex'>
            <div class='home-item flex-column' v-for='item in visitorList' :key='item.index'>
              <div class='home-item-label' :style="{ 'color' : item.type == 2 ? '#ffaf38' : '#4fa5ff' }">
                {{ item.label }}</div>
              <div class='home-item-value'>{{ customCount[item.value] }}</div>
            </div>
          </div>
          <div style='width:300px;margin:20px 0;'>
            <el-date-picker v-model="timeRanger" @change='changeTime' type="daterange" value-format='yyyy-MM-dd'
              range-separator="~" start-placeholder="开始" end-placeholder="结束">
            </el-date-picker>
          </div>
          <div id='chart-line' style='width:100%;height:400px'></div>
        </div>
        <div class='home-table flex-column'>
          <div class='home-table-title'>应用访问量排名 TOP10</div>
          <div class='home-ranking'>
            <el-row style='margin-bottom:20px;'>
              <el-col :span='4'>排名</el-col>
              <el-col :span='16'>应用名称</el-col>
              <el-col :span='4'></el-col>
            </el-row>
            <el-row v-for='(item, index) in rankingList' :key='item.index' style='height:40px'>
              <el-col :span='4'><span class='ranking-item-icon'
                  :style="{ 'background-color' : index == 0 ? '#F56C6C' : index == 1 ? '#E6A23C' : '#e9df5f' }">{{ index + 1 }}</span></el-col>
              <el-col :span='16'>{{ item.appName }}</el-col>
              <el-col :span='4' style='text-align:right'>{{ item.total }}</el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import api from '@/api/api'

export default {
  data () {
    return {
      drawer: false,
      timeRanger: [],
      rankingList: [],
      topList: [],
      appList: [],
      companyList: [],
      countList: [],
      customCount: {},
      visitorList: [
        { label: '今日访问量（PV）', value: 'dayNum', type: 1 }, { label: '今日访客数（UV）', value: 'dayCustom', type: 2 }, { label: '总访问量（PV）', value: 'allNum', type: 1 }, { label: '总访客数（UV）', value: 'allCustom', type: 2 },
      ],
      queryDate: [],
      companyInfo: {}
    }
  },
  created () {
    this.companyInfo = JSON.parse(window.localStorage.getItem('company')) || {}
    if (this.companyInfo.status == 1) {
      this.getNowDate()
      this.$nextTick(() => {
        this.queryData()
      })
    }

  },

  methods: {
    getNowDate () {
      var today = new Date();
      // 获取年、月、日
      var year = today.getFullYear();
      var month = ("0" + (today.getMonth() + 1)).slice(-2);
      var day = ("0" + today.getDate()).slice(-2);
      // 构建日期字符串
      var nowDate = year + "-" + month + "-" + day;
      var today2 = new Date();
      // 获取当前日期的时间戳
      var timestamp2 = today2.getTime();
      // 计算7天前的时间戳（7天的毫秒数 = 7 * 24 * 60 * 60 * 1000）
      var sevenDaysAgoTimestamp = timestamp2 - (7 * 24 * 60 * 60 * 1000);
      var sevenDaysAgo = new Date(sevenDaysAgoTimestamp);
      var year2 = sevenDaysAgo.getFullYear();
      var month2 = ("0" + (sevenDaysAgo.getMonth() + 1)).slice(-2);
      var day2 = ("0" + sevenDaysAgo.getDate()).slice(-2);
      var sevenDate = year2 + "-" + month2 + "-" + day2;
      this.timeRanger = [sevenDate, nowDate]
      let parmas = {}
      parmas.startTime = sevenDate
      parmas.endTime = nowDate
      this.queryPvList(parmas)
    },
    changeTime (val) {
      let parmas = {}
      parmas.startTime = val ? val[0] : ''
      parmas.endTime = val ? val[1] : ''
      this.queryPvList(parmas)
    },
    queryData () {
      this.queryAppTopList()
      this.querySummaryList()
      this.queryAppSummaryList()
      this.queryAppList()
      this.queryCompanyList()
    },
    queryAppList () {
      api.queryApp({
        pageNum: 1,
        pageSize: 99,
        status: 0,
      }).then(res => {
        if (res.code == 200) {
          this.appList = res.data.list
        }
      })
    },
    queryCompanyList () {
      api.queryCompany({
        pageNum: 1,
        pageSize: 99,
        status: 0,
      }).then(res => {
        if (res.code == 200) {
          this.companyList = res.data.list
        }
      })
    },
    queryPvList (parmas) {
      api.getPvList(parmas).then(res => {
        if (res.code == 200) {
          let dataX = []
          let dataY = []
          let dataY2 = []
          if (res.data) {
            res.data.forEach(item => {
              dataX.push(item.appName)
              dataY.push(item.total)
              dataY2.push(item.uv)
            })
          }
          this.drawChartLine(dataX, dataY, dataY2)
        }
      })
    },
    queryAppTopList () {
      api.homeAppTopList().then(res => {
        if (res.code == 200) {
          this.rankingList = res.data
        }
      })
    },
    querySummaryList () {
      api.getSummaryList().then(res => {
        if (res.code == 200) {
          this.customCount = res.data
        }
      })
    },
    queryAppSummaryList () {
      api.getAppSummaryList().then(res => {
        if (res.code == 200) {
          this.countList = res.data.appHomes
        }
      })
    },
    drawChartLine (datax, datay, datay2) {
      var myChart = echarts.init(document.getElementById('chart-line'));
      var option = {
        color: ['#4e9ded', '#FF8A22'],
        tooltip: {
          trigger: 'axis'
        },
        legend: {
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: datax
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '访问量',
            type: 'line',
            stack: 'Total',
            data: datay
          },
          {
            name: '访客量',
            type: 'line',
            stack: 'Total',
            data: datay2
          }
        ]
      }
      myChart.setOption(option);
    },
    drawChartCircle (data) {
      var myChart = echarts.init(document.getElementById('chart-circle'));
      var option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: data,
          }
        ]
      };
      myChart.setOption(option);
    },
    goAuthentication (type) {
      this.$router.push(`/companyManage/authentication?type=${type}`)
    }
  }
}
</script>
<style lang="scss">
.companyInfo {
  min-height: 1080px;
}
.home-item {
  width: calc(24% - 24px);
  height: 100px;
  padding: 10px;
  border-radius: 2px;
  background-color: #f2f6fc;
  .home-item-label {
    margin: 12px;
    font-size: 16px;
    color: #66b1ff;
  }
}
.home-title-tips {
  color: #ffbc00;
  line-height: 48px;
}
.home-card-text {
}
.home-table {
  width: 26%;
  height: 100%;
  background-color: #fff;
  border-radius: 4px;
  padding-right: 20px;
  .home-table-title {
    font-size: 16px;
    letter-spacing: 1px;
    color: #0061e3;
    text-align: center;
    line-height: 32px;
  }
  .home-ranking {
    width: 100%;
    height: calc(100% - 32px);
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 16px;
  }
  .el-col {
    text-align: center;
    margin: 4px 0;
  }
}
.home-item-value {
  font-size: 18px;
  color: #606266;
  font-weight: 600;
  text-align: right;
  margin: 12px 16px;
}
.home-bottom-left {
  width: calc(73% - 64px);
  height: calc(100% - 16px);
  background-color: #fff;
  border-radius: 4px;
  padding: 8px 12px 0 20px;
}
.home-title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin: 12px 20px;
}
.border-w {
  background-color: #fff;
  border-radius: 4px;
}
.home-card {
  width: calc(25% - 80px);
  max-width: 400px;
  height: 100px;
  border-radius: 4px;
  padding: 20px;
  background: linear-gradient(45deg, #409eff, #c6e2ff);
  font-size: 18px;
  font-weight: 600;
  font-family: "黑体";
  color: #fff;
}

.ranking-item-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #e1e2e3;
  color: #fff;
  text-align: center;
  line-height: 18px;
  margin-right: 12px;
}
</style>